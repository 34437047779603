<template>
    <div class="header-nav">
        <v-container>
            <v-toolbar color="white">
                <template v-slot:prepend>
                    <router-link to="/">
                        <v-img
                            height="70px"
                            width="70px"
                            alt="Logo du site internet PinsDLP"
                            :lazy-src="
                                require('../assets/images/pins/PinsDLP2.png')
                            "
                            :src="require('../assets/images/pins/PinsDLP2.png')"
                        >
                            <template v-slot:placeholder>
                                <div
                                    class="d-flex align-center justify-center fill-height"
                                >
                                    <v-progress-circular
                                        color="grey-lighten-4"
                                        indeterminate
                                    ></v-progress-circular>
                                </div>
                            </template>
                        </v-img>
                    </router-link>
                    <div class="search-box mx-3" @focusin="searchOpen = true">
                        <v-text-field
                            v-if="$route.path !== '/menu-pins'"
                            v-model="searchQuery"
                            label="Recherche pin's"
                            append-inner-icon="$magnify"
                            @click:append-inner="onSubmit"
                            @keyup.enter="onSubmit"
                            hide-details
                            class="displayNone"
                            variant="outlined"
                            single-line
                            clearable
                        ></v-text-field>
                    </div>
                </template>
                <template v-slot:append>
                    <div id="menuNoToggle">
                        <v-btn
                            flat
                            to="/"
                            class="text-none custom-btn"
                            aria-label="Bouton vers l'accueil"
                            >Accueil</v-btn
                        >
                        <v-menu open-on-click>
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    flat
                                    v-bind="props"
                                    class="text-none custom-btn"
                                    aria-label="Bouton vers les actualités"
                                >
                                    Actualités
                                    <template v-slot:append>
                                        <v-icon
                                            icon="$chevronDown"
                                            class="size20"
                                        >
                                        </v-icon>
                                    </template>
                                </v-btn>
                            </template>
                            <v-card>
                                <v-list>
                                    <v-list-item
                                        v-for="(item, i) in items"
                                        :key="i"
                                        :disabled="item.disable === 'true'"
                                    >
                                        <v-btn
                                            flat
                                            large
                                            :to="item.route"
                                            class="text-none custom-btn"
                                            :aria-label="`Bouton vers ${item.text}`"
                                            >{{ item.text }}
                                        </v-btn>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </v-menu>
                        <v-btn
                            flat
                            to="/a-propos"
                            class="text-none custom-btn"
                            aria-label="Bouton vers l'unviers du pin's"
                            >L'univers du pin's</v-btn
                        >
                        <v-btn
                            flat
                            to="/menu-pins"
                            class="text-none custom-btn"
                            aria-label="Bouton vers les pin's"
                            >Les pin's</v-btn
                        >
                    </div>
                    <nav role="navigation" class="navbar">
                        <div id="menuToggle">
                            <input
                                type="checkbox"
                                ref="menuCheckbox"
                                @click="toggleMenu"
                                aria-label="Afficher/cacher le menu"
                            />
                            <span></span>
                            <span></span>
                            <span></span>
                            <v-row id="menu" class="text-center">
                                <v-col cols="12">
                                    <router-link
                                        @click="toggleMenuClose()"
                                        to="/"
                                        aria-label="Bouton vers l'accueil"
                                        >Accueil</router-link
                                    >
                                </v-col>
                                <v-col cols="12">
                                    <router-link
                                        @click="toggleMenuClose()"
                                        to="/menu-articles"
                                        aria-label="Bouton vers les actualités"
                                        >Les articles</router-link
                                    >
                                </v-col>

                                <v-col cols="12">
                                    <router-link
                                        @click="toggleMenuClose()"
                                        to="/menu-affiches"
                                        aria-label="Bouton vers les actualités"
                                        >Les affiches</router-link
                                    >
                                </v-col>
                                <v-col cols="12">
                                    <router-link
                                        @click="toggleMenuClose()"
                                        to="/a-propos"
                                        aria-label="Bouton vers l'univers du pin's"
                                        >L'univers du pin's</router-link
                                    >
                                </v-col>
                                <v-col cols="12">
                                    <router-link
                                        @click="toggleMenuClose()"
                                        to="/menu-pins"
                                        aria-label="Bouton vers les pin's"
                                        >Les pin's</router-link
                                    >
                                </v-col>
                            </v-row>
                        </div>
                    </nav>
                </template>
            </v-toolbar>
            <!--Faire avec v-card et v list-->
            <div
                class="search-results"
                v-if="searchQuery && searchOpen && filteredPins.length > 0"
            >
                <v-card>
                    <v-list>
                        <v-list-item v-for="pin in filteredPins" :key="pin.id">
                            <router-link :to="generatePinURL(pin)">
                                <v-row class="align-center">
                                    <v-col cols="auto">
                                        <v-img
                                            v-if="pin.pins_src"
                                            :src="`${pin.pins_src}`"
                                            :alt="
                                                'Photo du pin ' + pin.pins_name
                                            "
                                            :width="80"
                                            aspect-ratio="1/1"
                                        ></v-img>
                                        <v-img
                                            v-else
                                            src="http://localhost:8080/img/WaitingImg.63f643d4.png"
                                            alt="Image en cours d'implémentation"
                                            :width="80"
                                            aspect-ratio="1/1"
                                        ></v-img>
                                    </v-col>
                                    <v-col>
                                        <span class="titleImg">{{
                                            pin.pins_name
                                        }}</span>
                                    </v-col>
                                </v-row>
                            </router-link>
                        </v-list-item>
                    </v-list>
                </v-card>
            </div>
        </v-container>
    </div>
</template>

<script>
//TODO faire un menu avec v-toolbar
//TODO Pour la serceah faire un v-btn qui après envoie
export default {
    name: "NavBar",
    mounted() {
        const app = document.querySelector("#app");
        app.addEventListener("click", (event) => {
            const menu = document.querySelector("#menu");
            if (!menu.parentNode.contains(event.target)) {
                this.toggleMenuClose();
            }
        });
        document.addEventListener("click", this.closeSearchResults);
    },
    computed: {
        allPins() {
            return this.$store.state.allPins;
        },
        filteredPins() {
            if (!this.searchQuery) {
                return [];
            }

            // Filtrer les pins en fonction de la recherche
            return this.allPins.filter((pin) =>
                pin.pins_name
                    .toLowerCase()
                    .includes(this.searchQuery.toLowerCase())
            );
        },
    },
    data() {
        return {
            searchQuery: "",
            menuOpen: false,
            searchOpen: false,
            searchResults: [],
            dialog: false,
            items: [
                {
                    text: "Les articles",
                    route: "/menu-articles",
                    disable: "false",
                },
                {
                    text: "Les affiches des pin's",
                    route: "/menu-affiches",
                    disable: "false",
                },
            ],
        };
    },
    methods: {
        generatePinURL(pin) {
            const formattedName = this.formatName(pin.pins_name);
            return {
                name: "Pins",
                params: { name: formattedName + "_" + pin.id },
            };
        },
        formatName(name) {
            // Transformation du nom en minuscules, suppression des espaces et des accents
            return name
                .toLowerCase()
                .replace(/ /g, "-")
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "");
        },
        openSearchDialog() {
            this.dialog = true;
        },
        closeSearchResults(event) {
            const searchBox = this.$el.querySelector(".search-box");
            if (searchBox !== null) {
                const isClickInsideSearchBox = searchBox.contains(event.target);
                if (!isClickInsideSearchBox) {
                    this.searchOpen = false;
                }
            }
        },
        onSubmit() {
            this.$store.commit("setSearchQuery", this.searchQuery);
            this.searchQuery = "";
            this.$router.push("/menu-pins");
            sessionStorage.clear();
        },

        toggleMenu() {
            if (this.menuOpen === false) {
                this.menuOpen = true;
            } else {
                this.menuOpen = false;
            }
        },
        toggleMenuClose() {
            if (this.$refs.menuCheckbox.checked) {
                this.$refs.menuCheckbox.checked = false;
                this.menuOpen = false;
            }
        },
        beforeRouteLeave() {
            this.searchOpen = false;
        },
    },
};
</script>
